/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from 'react'
import { Box, chemistryTheme, Container, isMobile } from 'chemistry'
import { ChakraProvider } from '@chakra-ui/react'
import { overviewReducer } from './store/reducer'
import { onLoadPages } from './store/actions'
import throttle from '../../common/throttle'
import Grid from './components/Grid/Grid'
import { OVERVIEW_GRID_DATA, OVERVIEW_VIEW_SIZE } from 'common/constants'
import ProductsPlaceholdersList from 'client/common/components/ProductsPlaceholdersList'
import PopularSearchTerms from './components/PopularSearchTerms/PopularSearchTerms'
import { TopNavigation } from 'client/fragments/softContentOverview/components/TopNavigation/TopNavigation'

export const LISTER_OVERVIEW_GRID_DATA = {
  ...OVERVIEW_GRID_DATA,
  desktopColumns: 3,
  desktopRowsPerLoad: 4,
  visibleRows: 4
}
const Component = props => {
  const {
    contentfulTranslations,
    pageTitle,
    faded = false,
    popularSearchTerms = [],
    filter,
    lang,
    format,
    inclusionTags,
    exclusionTags,
    translations,
    xRequestUri
  } = props
  const availablePageTypes = filter.items.map(item => item.value)
  const [pagesStore, dispatchPages] = useReducer(overviewReducer, {
    pages: [],
    totalNumberOfResults: 0,
    page: 1,
    isLoading: true
  })

  const [selectedPopular, setSelectedPopular] = React.useState('')
  const [filters, setFilters] = React.useState([])

  const href = global.document?.location?.href
  const { searchParams, pathName } = href ? new URL(href) : {}

  const isListerSearch = [pathName, xRequestUri].find(it => it?.includes('/search'))
  const gridData = isListerSearch ? LISTER_OVERVIEW_GRID_DATA : OVERVIEW_GRID_DATA
  const columnsCount = isMobile() ? gridData.mobileColumns : gridData.desktopColumns
  const itemsPerLoad = faded ? OVERVIEW_VIEW_SIZE + columnsCount : OVERVIEW_VIEW_SIZE

  const loadPages = item =>
    throttle(
      () =>
        onLoadPages({
          format,
          lang,
          inclusionTags,
          exclusionTags,
          viewSize: itemsPerLoad,
          term: item.term,
          pageTypes: filters || [],
          allPageTypes: availablePageTypes,
          ...item,
          dispatchPages
        }),
      300
    )

  useEffect(() => {
    const filterProp = searchParams.get('pageTypes')?.split(',') ?? []
    const availableParams = filterProp.filter(page => availablePageTypes.includes(page))
    setFilters(availableParams)
    const term = searchParams.get('term') ?? ''
    if (popularSearchTerms?.includes(term)) {
      setSelectedPopular(term)
    }
    loadPages({
      pageTypes: availableParams,
      term: ['term', 'text'].map(it => searchParams.get(it)).find(Boolean) ?? ''
    })
  }, [])

  const setFiltersHandler = filtered => {
    setFilters(filtered ?? [])
    loadPages({
      pageTypes: filtered,
      term: searchParams.get('term') ?? ''
    })
  }

  const loadMoreHandler = () => {
    const term = searchParams.get('term') || searchParams.get('text') || ''
    loadPages({ page: pagesStore.page + 1, term, reset: false })
  }

  const searchSubmitHandler = value => {
    setSelectedPopular(popularSearchTerms?.includes(value) ? value : '')
    loadPages({ term: value || '' })
  }

  const selectHandler = value => {
    setSelectedPopular(value)
    loadPages({ term: value })
  }

  return (
    <ChakraProvider resetCSS={false} theme={chemistryTheme}>
      <Container data-soft-content-overview-marker className='soft-content-overview'>
        <TopNavigation
          {...{
            pageTitle,
            format,
            lang,
            contentfulTranslations,
            filter,
            filters,
            translations,
            isListerSearch,
            setFiltersHandler,
            searchSubmitHandler
          }}
        />
        {!!popularSearchTerms?.length && (
          <PopularSearchTerms
            contentfulTranslations={contentfulTranslations}
            items={popularSearchTerms}
            selectedValue={selectedPopular}
            selectHandler={value => selectHandler(value)}
          />
        )}

        {pagesStore.isMainLoading ? (
          <Box mt='md'>
            <ProductsPlaceholdersList count={4} perRow={4} />
          </Box>
        ) : (
          <Grid
            translations={contentfulTranslations}
            faded={faded}
            totalSize={pagesStore.totalNumberOfResults}
            pages={pagesStore.pages}
            loadNextPage={() => loadMoreHandler()}
            isLoading={pagesStore.isLoading}
            isListerSearch={isListerSearch}
          />
        )}
      </Container>
    </ChakraProvider>
  )
}

export default { Component }
