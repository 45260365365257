import axios from 'axios'
import { getOverviewResults } from '../../../common/xhr/gateway'
import { OVERVIEW_VIEW_SIZE } from 'common/constants'
import { dispatchPageBusEvent } from 'client/common/pageBus'

export const GET_PAGES = 'GET_PAGES'
export const SET_PAGES = 'SET_PAGES'

const updateParam = (searchParams, param, value) => {
  if (searchParams.get(param) && value) {
    searchParams.set(param, value)
  } else if (value) {
    searchParams.append(param, value)
  } else {
    searchParams.delete(param)
  }
}

const updateParams = (searchParams, params) => {
  Object.keys(params).forEach(param => updateParam(searchParams, param, params[param]))
}

export const onLoadPages = ({
  format,
  lang,
  sortBy = 'publishDateTime',
  sortOrder = 'descending',
  inclusionTags,
  exclusionTags,
  term,
  pageTypes,
  viewSize = OVERVIEW_VIEW_SIZE,
  page = 1,
  reset = true,
  allPageTypes,
  dispatchPages
}) => {
  dispatchPages({
    type: GET_PAGES,
    reset
  })
  page = reset ? 1 : page
  const currentUrl = new URL(window.location.href)

  const params = { pageTypes: pageTypes.length ? pageTypes.join(',') : '' }
  const isSearch = currentUrl.searchParams.get('text')
  if (!isSearch) params.term = term
  updateParams(currentUrl.searchParams, params)

  global.window.history.pushState({ timestamp: new Date().getTime() }, '', currentUrl)
  const url = getOverviewResults({
    lang,
    format,
    sortBy,
    sortOrder,
    inclusionTags,
    exclusionTags,
    term,
    pageTypes: pageTypes.length ? pageTypes : allPageTypes,
    viewSize,
    page
  })

  return axios
    .get(url)
    .then(resp => {
      const response = resp?.data?.response || {}
      dispatchPages({
        type: SET_PAGES,
        pages: response.results,
        totalNumberOfResults: response.totalNumberOfResults,
        page,
        reset
      })
      dispatchPageBusEvent({ type: 'SOFT_CONTENT_SEARCH_RENDERED', props: { count: response?.totalNumberOfResults } })
      return resp
    })
    .catch(e => {
      dispatchPages({
        type: SET_PAGES,
        pages: [],
        reset
      })
    })
}
