/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Box, LazyGrid, NoResultsContainer } from 'chemistry'
import { OVERVIEW_GRID_DATA } from 'common/constants'
import ProductsPlaceholdersList from 'client/common/components/ProductsPlaceholdersList'
import { toGridItemComponent } from 'client/fragments/softContentOverview/components/Grid/toGridItemComponent'
import { LISTER_OVERVIEW_GRID_DATA } from 'client/fragments/softContentOverview'

const Grid = ({
  pages: items,
  translations,
  faded,
  totalSize,
  isLoading,
  fadeColor = '#FFFFFF',
  isListerSearch,
  loadNextPage = () => {}
}) => {
  const [pages, setPages] = useState(items)

  useEffect(() => {
    setPages(items)
  }, [items])

  const gridData = isListerSearch ? LISTER_OVERVIEW_GRID_DATA : OVERVIEW_GRID_DATA

  return (
    <Box mt='48px'>
      {!!pages.length || isLoading ? (
        <>
          <LazyGrid
            fadeColor={fadeColor}
            items={pages}
            loadMoreText={translations.loadMore ?? '>'}
            withFade={faded}
            isLoading={isLoading}
            totalSize={totalSize}
            {...gridData}
            loadMore={loadNextPage}
            gridItemComponent={toGridItemComponent(translations)}
          />
          {isLoading && (
            <Box mt='4px'>
              <ProductsPlaceholdersList count={4} perRow={4} />
            </Box>
          )}
        </>
      ) : (
        <NoResultsContainer text={translations.noResults} />
      )}
    </Box>
  )
}

export default Grid
